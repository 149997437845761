//
// Tooltip (Override some default Bootstrap styles)
// --------------------------------------------------

.tooltip {
  font-family: $font-family-base;
  &.bs-tooltip-top {
    .arrow::before { border-top-color: $tooltip-arrow-color; }
  }
  &.bs-tooltip-right {
    .arrow::before { border-right-color: $tooltip-arrow-color; }
  }
  &.bs-tooltip-bottom {
    .arrow::before { border-bottom-color: $tooltip-arrow-color; }
  }
  &.bs-tooltip-left {
    .arrow::before { border-left-color: $tooltip-arrow-color; }
  }
  &.show { opacity: $tooltip-opacity; }
}
.tooltip-inner {
  padding: .35rem .6rem;
  border-radius: 0;
  background-color: $tooltip-bg;
  color: $tooltip-color;
  font: {
    size: $font-size-sm;
    weight: 600;
  }
  letter-spacing: .05em;
}

// For demo purpose only. TODO: remove on production
.example-tooltip .tooltip {
  display: inline-block;
  position: relative;
  margin: 10px 20px;
  opacity: 1;
  &.bs-tooltip-top,
  &.bs-tooltip-bottom {
    .arrow {
      left: 50%;
      margin-left: -6px;
    }
  }
  &.bs-tooltip-right,
  &.bs-tooltip-left {
    .arrow {
      top: 50%;
      margin-top: -6px;
    }
  }
}
