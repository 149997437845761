//
// Search Box
// --------------------------------------------------

.search-box,
.search-box > input,
.search-box > .easy-autocomplete > input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.search-box {
  transition: all .3s;
  background-color: $white;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  &::before {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -14px;
    font: {
      family: feather;
      size: 20px;
    }
    content: '\e9c1';
    z-index: 5;
  }
  > .easy-autocomplete > input,
  > input {
    padding: {
      right: 30px;
      left: 32px;
    }
    border: 0;
    color: $body-color;
    font-size: $font-size-base;
    -webkit-appearance: none;
    &:focus { outline: none; }
  }
  .search-close {
    position: absolute;
    top: 50%;
    right: -5px;
    width: 50px;
    height: 50px;
    margin-top: -25px;
    color: $body-color;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    z-index: 5;
  }
  &.is-open {
    opacity: 1;
    visibility: visible;
  }
  @media (max-width: $screen-lg) {
    &::before { left: 12px; }
    > .easy-autocomplete > input,
    > input {
      padding: {
        right: 40px;
        left: 40px;
      }
      font-size: $font-size-md;
    }
    .search-close { right: 0; }
  }
}

// Search Autocomplete
.easy-autocomplete {
  width: 100% !important;
}

.easy-autocomplete-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 0;
  border-radius: 0;
  background-color: $white;
  box-shadow: $box-shadow-autocomplete;
  > ul {
    display: none;
    margin: 0;
    margin-top: 1px;
    padding: 10px 25px 20px;
    list-style: none;
    li { margin: 0; }
  }
}

.eac-item > a {
  display: inline-block;
  padding: 5px 0;
  color: $body-color;
  font-size: $font-size-md;
  text-decoration: none;
  > i {
    margin-right: 12px;
    color: $primary;
    vertical-align: middle;
  }
  &:hover > .ac-title {
    text-decoration: underline;
  }
}
