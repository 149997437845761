//
// List Group
// --------------------------------------------------
.list-group { background-color: $white; }
.list-group-item {
  padding: {
    top: 16px;
    bottom: 16px;
  }
  transition: all .3s;
  border: 1px solid $border-color !important;
  border-radius: 0 !important;
  color: $gray-dark;
  font: {
    size: $navi-link-font-size;
    weight: 600;
  }
  letter-spacing: $navi-link-letter-spacing;
  text: {
    transform: $navi-link-text-transform;
    decoration: none;
  }
  i {
    display: inline-block;
    margin-top: -1px;
    margin-right: 8px;
    font-size: 1.2em;
    vertical-align: middle;
  }
  p, ul, ol, li, span:not(.badge), small {
    font-weight: normal !important;
    letter-spacing: 0;
    text-transform: none;
  }
  h1, .h1, h2, .h2, h3, .h3,
  h4, .h4, h5, .h5, h6, .h6 {
    letter-spacing: 0;
    text-transform: none;
  }
  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: transparent;
    content: '';
  }
  &.active:not(.disabled) {
    border-color: $border-color;
    background: $white;
    color: $primary;
    cursor: default;
    pointer-events: none;
    > i, i { color: $primary !important; }
    &::before { background-color: $primary; }
  }
  &.disabled {
    color: $gray;
    cursor: default;
    pointer-events: none;
  }
  .badge { margin: 0; }
}
a.list-group-item,
.list-group-item-action {
  color: $gray-dark;
  font-weight: $navi-link-font-weight;
  &:hover { background-color: $gray-lighter; }
  &:hover, &:active {
    &:not(.disabled) {border-color: $border-color; }
  }
}
.list-group-flush .list-group-item {
  border: {
    right: 0 !important;
    left: 0 !important;
  }
}

// Contextual classes
.list-group-item-primary {
  @include list-group-variant($primary, rgba($primary, .1), lighten($primary, 32%));
}
.list-group-item-secondary {
  @include list-group-variant($gray-dark, $gray-lighter, $border-color);
}
.list-group-item-accent {
  @include list-group-variant($accent, rgba($accent, .1), lighten($accent, 32%));
}
.list-group-item-info {
  @include list-group-variant($info, rgba($info, .1), lighten($info, 20%));
}
.list-group-item-success {
  @include list-group-variant(darken($success, 3%), rgba($success, .1), lighten($success, 30%));
}
.list-group-item-warning {
  @include list-group-variant(darken($warning, 3%), rgba($warning, .1), lighten($warning, 30%));
}
.list-group-item-danger {
  @include list-group-variant(darken($danger, 3%), rgba($danger, .1), lighten($danger, 22%));
}
.list-group-item-dark {
  @include list-group-variant($white, $gray-darker, $gray-darker);
}
a:hover, a.active,
.list-group-item-action:hover,
.list-group-item-action.active {
  &.list-group-item-primary { background-color: rgba($primary, .18); }
  &.list-group-item-accent { background-color: rgba($accent, .18); }
  &.list-group-item-info { background-color: rgba($info, .18); }
  &.list-group-item-success { background-color: rgba($success, .18); }
  &.list-group-item-warning { background-color: rgba($warning, .18); }
  &.list-group-item-danger { background-color: rgba($danger, .18); }
  &.list-group-item-dark { background-color: darken($gray-darker, 3%); }
}
