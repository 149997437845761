//
// Wizard
// --------------------------------------------------

.wizard {
  background-color: $white;
  box-shadow: $box-shadow-card;
  .wizard-steps {
    .wizard-step {
      width: 100%;
      margin: {
        bottom: -1px;
        left: -1px;
      }
      padding: 18px 15px;
      transition: all .3s;
      border: 1px solid $border-color;
      color: $gray-darker;
      font-weight: 600;
      text: {
        align: center;
        decoration: none;
      }
      > i {
        display: inline-block;
        margin: {
          top: -2px;
          right: 6px;
        }
        color: $success;
        font-size: 1.2em;
        vertical-align: middle;
      }
      > .wizard-icon {
        display: block;
        margin: 0 auto 10px;
        color: $gray;
        font-size: 1.6em;
      }
      &:hover {
        background-color: $gray-lighter;
      }
      &.active {
        border-color: $primary;
        background-color: $primary;
        color: $white;
        box-shadow: 0 12px 18.8px 1.2px rgba($primary, .24);
        cursor: default;
        pointer-events: none;
        > .wizard-icon { color: $white; }
      }
    }
  }
  .wizard-body { padding: 40px 25px 30px; }
  .wizard-footer {
    padding: 15px 25px;
    border-top: 1px solid $border-color;
  }
}
