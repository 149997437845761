//
// Carousel (based on Owl Carousel)
// --------------------------------------------------

.owl-carousel {

  // Navs
  .owl-nav button.owl-next,
  .owl-nav button.owl-prev {
    display: block;
    position: absolute;
    top: 50%;
    width: $carousel-navs-size;
    height: $carousel-navs-size;
    margin-top: -(($carousel-navs-size / 2) + 27);
    transition: all .3s;
    border-radius: 50%;
    background-color: $white;
    color: $gray-dark;
    line-height: $carousel-navs-size;
    text-align: center;
    box-shadow: $box-shadow-carousel-navs;
    text-align: center;
    opacity: .65;
    &:hover {
      color: $primary;
      opacity: 1;
    }
    &:focus { outline: none; }
    &::after {
      font: {
        family: feather;
        size: 21px;
      }
    }
    &.disabled { opacity: 0; }
  }
  .owl-nav button.owl-prev {
    left: 25px;
    &::after {
      margin-left: -2px;
      content: '\e92f';
    }
}
  .owl-nav button.owl-next {
    right: 25px;
    &::after {
      margin-right: -2px;
      content: '\e930';
    }
  }

  // Dots
  .owl-dots {
    display: block;
    width: 100%;
    padding: 16px 0;
    text-align: center;
    .owl-dot {
      display: inline-block;
      width: $carousel-dots-size;
      height: $carousel-dots-size;
      margin: 0 5px;
      transition: all .25s;
      border-radius: 50%;
      background-color: $gray;
      opacity: .4;
      vertical-align: middle;
      &.active {
        transform: scale(2);
        background-color: $primary;
        opacity: 1;
      }
      &:focus { outline: none; }
    }
  }

  // Dots Inside
  &.dots-inside {
    .owl-nav button.owl-next,
    .owl-nav button.owl-prev {
      margin-top: -($carousel-navs-size / 2);
    }
    .owl-dots {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 15px;
      padding-top: 6px;
      .owl-dot {
        background-color: $white;
        opacity: 1;
      }
    }
  }

  &.carousel-flush {
    .owl-dots { padding-top: 0 !important; }
    .owl-item { padding: 0 15px; }
  }
}


.owl-slide-item{
  background-size: cover;

  &::before {
    content: '.';
    position: absolute;
    width: 100%;
    height: 100%;
    display: block;
    background-color: #0008;
    z-index: 0;
  }
  & > .container{
    height: 70vh;
    position: relative;
    z-index: 1;
  }
}
