//
// Code (Override some default Bootstrap styles) + Extra
// -----------------------------------------------------

// User Input
kbd {
  border-radius: 0;
  background-color: $gray-darker;
}

// Code Block
pre {
  border-radius: 0;
  color: $body-color;
}

// Tabs
.nav-tabs.pre-nav-tabs {
  padding: floor($grid-vertical-step / 3);
  border: {
    top: 1px solid $border-color;
    right: 1px solid $border-color;
    left: 1px solid $border-color;
  }
  background-color: $gray-lighter;
  .nav-link {
    margin: {
      top: 5px;
      bottom: 5px;
    }
  }
}
