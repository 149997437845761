//
// Mixins
// --------------------------------------------------

// Font smoothing
@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Unstyled list
@mixin list-unstyled {
  padding-left: 0;
  list-style: none;
}

// Gradient background
@mixin gradient-background($gradient-from: $gradient-color-1, $gradient-to: $gradient-color-2) {
  background: $gradient-from !important;
  background: linear-gradient(to right, $gradient-from 0%, $gradient-from 20%, $gradient-to 100%) !important;
}

// Vertical gradient background
@mixin gradient-background-vertical($gradient-from: $gradient-color-1, $gradient-to: $gradient-color-2) {
  background: $gradient-from !important;
  background: linear-gradient(to bottom, $gradient-from 0%, $gradient-from 20%, $gradient-to 100%) !important;
}

// Placeholder text
@mixin placeholder($color: $gray) {
  // Firefox
  &::-moz-placeholder {
    color: $color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder {
    color: $color;
  } // Internet Explorer 10+
  &::-webkit-input-placeholder  {
    color: $color;
  } // Safari and Chrome
}

// Appearance
@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}


// Alert Variants
@mixin alert-variant($color, $bg-color, $border-color) {
  background-color: $bg-color;
  color: $color;
  &::before { border-color: $border-color; }
  > *,
  h1, h2, h3, h4, h5, h6, p, ul, ol, a {
    &:not(.text-white),
    &:not(.text-light) { color: $color; }
  }
  .alert-close { color: $color; }
}

// List Group Variants
@mixin list-group-variant($color, $bg-color, $border-color) {
  border-color: $border-color !important;
  background-color: $bg-color;
  color: $color !important;
  > *,
  h1, h2, h3, h4, h5, h6, p, ul, ol, a { color: $color !important; }
}
