//
// Mobile Menu
// --------------------------------------------------

.mobile-menu {
  border: 0;
  .mobile-menu-link {
    display: block;
    position: relative;
    padding: 20px 0;
    padding-right: 58px;
    padding-left: 30px;
    transition: color .3s;
    color: $gray-darker;
    font: {
      size: floor($font-size-base * .8125);
      weight: $navi-link-font-weight;
    }
    letter-spacing: $navi-link-letter-spacing;
    text: {
      transform: $navi-link-text-transform;
      decoration: none;
    }
    &:hover, &.active { color: $primary; }
    &.active::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: $primary;
      content: '';
    }
  }
  .card {
    margin-top: -1px;
    border: 0;
    box-shadow: none;
  }
  .card::before { display: none; }
  .card-header {
    position: relative;
    border: {
      top: 1px solid $border-color;
      bottom: 1px solid $border-color;
    }
  }
  .card:first-child .card-header { border-top: 0; }
  [data-toggle='collapse'] {
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 100%;
    padding: {
      right: 15px;
      left: 15px;
    }
    border-left: 1px solid $border-color;
    box-shadow: none !important;
    z-index: 5;
    &::before { display: none; }
    &::after {
      right: 15px;
      margin-top: -15px;
    }
  }
  .card-body {
    padding: {
      top: 10px;
      bottom: 18px;
    }
    background-color: lighten($gray-lighter, 2%);
    ul {
      margin: 0;
      padding: 0;
    }
  }
  .dropdown-header,
  .dropdown-item,
  .dropdown-item > a {
    padding: {
      right: 0;
      left: 0;
    }
    font-size: $font-size-md;
  }
  .dropdown-header {
    position: relative;
    margin-bottom: 0;
    padding: {
      top: 20px;
      bottom: 8px;
    }
    color: $gray-dark;
    font: {
      family: $font-family-base;
      size: floor($font-size-base * .8125);
      weight: 600;
    }
    letter-spacing: 0;
    text-transform: uppercase;
    > i { color: darken($gray, 5%); }
    &::after {
      display: block;
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 90px;
      height: 2px;
      @extend .bg-gradient;
      content: '';
    }
    & + li > a { padding-top: 16px; }
  }
  .mobile-sublevel {
    padding-bottom: 9px !important;
    li {
      border: 0;
      border-left: 2px solid $border-color;
      > a {
        position: relative;
        padding: 8px 0 8px 15px;
        font-size: floor($font-size-base * .8125);
        &::before {
          display: block;
          position: absolute;
          top: 50%;
          left: 0;
          width: 8px;
          height: 2px;
          background-color: $border-color;
          content: '';
        }
      }
    }
  }
}
