//
// Full Screen Overlay
// --------------------------------------------------

.fs-overlay-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: {
    top: 70px;
    bottom: 70px;
  }
  transition: all .35s;
  background: {
    position: center;
    color: $white;
    repeat: no-repeat;
    size: cover;
  }
  opacity: 0;
  visibility: hidden;
  z-index: 9900;
  &.in-view {
    opacity: 1;
    visibility: visible;
  }
}
.fs-overlay-inner {
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.fs-overlay-content {
  display: table-cell;
  transform: translateY(15px);
  transition: all .3s .25s;
  opacity: 0;
  vertical-align: middle;
  .input-group { box-shadow: $box-shadow-card; }
  .input-group-text {
    padding: {
      right: 0;
      left: 20px;
    }
    background: none;
    font-size: $font-size-lead;
  }
  .form-control {
    height: 80px;
    border: 0;
    font-size: $font-size-xl;
    &:hover { border-color: $border-color !important; }
    &:focus { box-shadow: none !important; }
  }
}
.in-view .fs-overlay-content {
  transform: translateY(0);
  opacity: 1;
}
.fs-overlay-close {
  display: block;
  position: absolute;
  top: 15px;
  right: 25px;
  width: 50px;
  height: 50px;
  transition: all .3s;
  border-radius: 50%;
  background-color: $white;
  font-size: 23px;
  line-height: 53px;
  text-align: center;
  box-shadow: $box-shadow-fullscreen-close;
  cursor: pointer;
  > i {
    display: inline-block;
    transition: all .3s;
  }
  &:hover {
    background-color: $gray-lighter;
    box-shadow: none;
    > i { transform: rotate(90deg); }
  }
}
