//
// Modal (Override some default Bootstrap styles)
// --------------------------------------------------

.modal { z-index: 1999; }
.modal-backdrop { z-index: 1900; }
.modal-content {
  border: {
    color: transparent;
    radius: 0;
  }
  box-shadow: $box-shadow-card;
}
.modal-header,
.modal-body,
.modal-footer {
  padding: {
    right: 20px;
    left: 20px;
  }
}
.modal-header,
.modal-footer { border-color: border-color; }
.modal-header {
  position: relative;
  .close {
    position: absolute;
    top: 5px;
    right: 12px;
  }
}
.modal-title {
  font-size: $font-size-h5;
}

// For demo purpose only. TODO: remove on production
.example-modal .modal {
  display: block;
  position: relative;
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
  z-index: 1;
}
