//
// Popover (Override some default Bootstrap styles)
// --------------------------------------------------

.popover {
  border-radius: 0;
  border-color: $border-color;
  font-family: $font-family-base;
  box-shadow: $box-shadow-base;
  &.bs-popover-top .arrow::before { border-top-color: darken($border-color, 4%); }
  &.bs-popover-right .arrow::before { border-right-color: darken($border-color, 4%); }
  &.bs-popover-bottom .arrow {
    &::before { border-bottom-color: darken($border-color, 4%); }
    &::after { border-bottom-color: #f7f7f7; }
  }
  &.bs-popover-left .arrow::before { border-left-color: darken($border-color, 4%); }
}
.popover-header {
  padding: .6rem .75rem;
  color: $headings-color;
  font: {
    family: $font-family-headings;
    size: $font-size-md;
    weight: 700;
  }
}
.popover-body {
  color: $body-color;
  font-size: floor($font-size-base * .8125);
}

// For demo purpose only. TODO: remove on production
.example-popover .popover {
  display: block;
  position: relative;
  width: 260px;
  margin: 1.25rem;
  float: left;
}
.bs-tooltip-bottom-demo,
.bs-tooltip-top-demo {
  .arrow {
    left: 50%;
    margin-left: -2px;
  }
}
.bs-tooltip-left-demo,
.bs-tooltip-right-demo {
  .arrow {
    top: 50%;
    margin-top: -6px;
  }
}
.bs-popover-bottom-demo,
.bs-popover-top-demo {
  .arrow {
    left: 50%;
    margin-left: -11px;
  }
}
.bs-popover-left-demo,
.bs-popover-right-demo {
  .arrow {
    top: 50%;
    margin-top: -8px;
  }
}
