//
// Animated Digits
// --------------------------------------------------

.animated-digits {
  display: table;
  position: relative;
  padding-bottom: 18px;
  &::after { @extend %divider; }
  .animated-digits-digit {
    display: block;
    margin: 0;
    font: {
      family: $font-family-headings;
      size: $font-size-h1;
      weight: bold;
    }
  }
  .animated-digits-text {
    margin: 0;
    margin-top: -5px;
  }
  .animated-digits-icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    margin-bottom: 5px;
    background-color: rgba($primary, .1);
    color: $primary;
    font-size: 20px;
    line-height: 44px;
    text-align: center;
  }
  &.text-white {
    &::after { background: $white !important; }
  }
}
.card .animated-digits { display: block; }
