//
// Input Group (Override some default Bootstrap styles)
// ----------------------------------------------------

.input-group-text {
  border: 0;
  border-radius: 0 !important;
  background-color: darken($gray-lighter, 1%);
  font: {
    size: $font-size-md;
    weight: 600;
  }
  > i { font-size: 1.1em; }
  .custom-control {
    height: 16px;
    min-height: auto;
    margin-bottom: 0;
    padding: 0;
    .custom-control-label {
      width: 16px;
      height: 16px;
      &::before, &::after {
        top: 0;
        left: 0;
      }
      &::before {
        border: 1px solid darken($border-color, 5%);
        background-color: $white;
      }
    }
    .custom-control-input:checked {
      & ~ .custom-control-label::before { border-color: $primary; }
    }
  }
}
.input-group-prepend,
.input-group-append {
  margin: 0 !important;
  .input-group-text + .input-group-text,
  .btn + .btn { margin-left: 2px; }
}

// Light Skin
.input-group-light-skin {
  .form-control {
    border-color: $border-light-color;
    background-color: transparent;
    color: $white;
    @include placeholder(rgba($white, .5));
    &:hover { border-color: rgba($white, .3) !important; }
    &:focus {
      border-color: rgba($white, .1) !important;
      background-color: rgba($white, .1);
    }
  }
  .input-group-text {
    background-color: rgba($white, .1);
    color: $white;
  }
}
