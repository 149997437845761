//
// Alert
// --------------------------------------------------

// Base class
.alert {
  display: block;
  position: relative;
  padding: $grid-vertical-step;
  border: 5px solid transparent;
  border-radius: 0;
  background: {
    position: center;
    repeat: no-repeat;
    size: cover;
    clip: padding-box;
  }
  i {
    display: inline-block;
    margin-top: -1px;
    font-size: 1.1em;
    vertical-align: middle;
  }
  a:not(.btn) { font-weight: 600; }
  > *,
  h1, h2, h3, h4, h5, h6, p, ul, ol, a {
    &:last-child:not(.btn) { margin: 0; }
  }
  &::before {
    display: block;
    position: absolute;
    top: -6px;
    right: -6px;
    bottom: -6px;
    left: -6px;
    border: 1px solid transparent;
    border-radius: 0;
    content: '';
    z-index: -1;
  }

  // Alert Dismissible
  .alert-dismissible {
    transition: opacity .4s;
    &.fade.show { opacity: 1; }
  }
  .alert-close {
    display: block;
    position: absolute;
    top: 7px;
    right: 9px;
    font: {
      family: feather;
      size: $font-size-base;
    }
    cursor: pointer;
    &::before { content: '\ea04'; }
  }
}
a.alert {
  text-decoration: none !important;
}

// Color Variants
.alert-secondary {
  @include alert-variant($gray-dark, rgba($gray-dark, .05), $border-color);
}
.alert-primary {
  @include alert-variant($primary, rgba($primary, .1), rgba($primary, .25));
}
.alert-accent {
  @include alert-variant($accent, rgba($accent, .1), rgba($accent, .25));
}
.alert-info {
  @include alert-variant($info, rgba($info, .1), rgba($info, .25));
}
.alert-success {
  @include alert-variant($success, rgba($success, .1), rgba($success, .25));
}
.alert-warning {
  @include alert-variant($warning, rgba($warning, .1), rgba($warning, .25));
}
.alert-danger {
  @include alert-variant($danger, rgba($danger, .1), rgba($danger, .25));
}

// With Background Image
.alert-image-bg {
  border: 0;
  &::before { display: none; }
}
