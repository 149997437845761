//
// Toast Notification
// --------------------------------------------------

.iziToast {
  border: 1px solid $border-color;
  border-radius: 0;
  background: $white;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    box-shadow: $box-shadow-base;
    content: '';
    z-index: -1;
  }
  > .iziToast-body {
    margin-left: 15px;
    > .iziToast-icon { margin-top: -10px; }
  }
  > .iziToast-close {
    width: 40px;
    background: none;
    opacity: 1;
    &::before {
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      font: {
        family: feather;
        size: $font-size-md;
      }
      text-align: center;
      content: '\ea04';
    }
  }
  &.iziToast-primary {
    border-color: rgba($primary, .5);
    color: $primary;
    > .iziToast-close { color: $primary; }
  }
  &.iziToast-accent {
    border-color: rgba($accent, .5);
    color: $accent;
    > .iziToast-close { color: $accent; }
  }
  &.iziToast-info {
    border-color: rgba($info, .5);
    color: $info;
    > .iziToast-close { color: $info; }
  }
  &.iziToast-success {
    border-color: rgba($success, .5);
    color: $success;
    > .iziToast-close { color: $success; }
  }
  &.iziToast-warning {
    border-color: rgba($warning, .5);
    color: $warning;
    > .iziToast-close { color: $warning; }
  }
  &.iziToast-danger {
    border-color: rgba($danger, .5);
    color: $danger;
    > .iziToast-close { color: $danger; }
  }
}
