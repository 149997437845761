//
// Portfolio specific styles
// --------------------------------------------------

.portfolio-card:not(.card) {
  transition: box-shadow .3s;
  text-align: center;
  .portfolio-card-body {
    padding: floor($grid-vertical-step / 1.2);
  }
}
.no-touchevents {
  .portfolio-card:not(.card):hover {
    box-shadow: $box-shadow-card;
    .portfolio-thumb { box-shadow: none; }
  }
}
.portfolio-thumb {
  display: block;
  transition: box-shadow .3s;
  box-shadow: $box-shadow-base;
  user-select: none;
  > img {
    display: block;
    width: 100%;
  }
}
.portfolio-meta {
  padding-bottom: floor($grid-vertical-step / 2.5);
  color: rgba($gray, .75);
  font: {
    size: floor($font-size-base * .6875);
    weight: 600;
  }
  i {
    display: inline-block;
    margin-right: 3px;
    font-size: 1.1em;
    vertical-align: middle;
  }
  > a {
    color: rgba($gray, .75);
    text-decoration: none;
  }
}
.portfolio-title {
  margin-bottom: floor($grid-vertical-step / 2);
  font-size: $font-size-xl;
  > a {
    transition: color .3s;
    color: $headings-color;
    text-decoration: none;
    &:hover { color: $primary; }
  }
}
.card.portfolio-card {
  .portfolio-thumb { box-shadow: none; }
  .portfolio-title { margin-bottom: 0; }
  .card-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: {
      right: $grid-vertical-step;
      left: $grid-vertical-step;
    }
    .portfolio-meta { padding-bottom: 0; }
  }
}
.portfolio-list {
  display: table;
  width: 100%;
  .portfolio-thumb,
  .card-body {
    display: table-cell;
    vertical-align: middle;
  }
  .portfolio-thumb {
    width: 46%;
    min-height: 240px;
    background: {
      position: center;
      repeat: no-repeat;
      size: cover;
    }
  }
  .card-body {
    padding: {
      top: ($grid-vertical-step * 3);
      bottom: ($grid-vertical-step * 3);
      left: ($grid-vertical-step * 1.5);
    }
  }
  .portfolio-title {
    margin-bottom: floor($grid-vertical-step / 2) !important;
  }
  @media (max-width: $screen-sm) {
    display: block;
    .portfolio-thumb,
    .card-body {
      display: block;
      width: 100%;
    }
    .card-body { padding: $grid-vertical-step; }
  }
}
