//
// Tables (Override some default Bootstrap styles)
// --------------------------------------------------

.table {
  thead th, td, th { border-color: $border-color; }
  thead th, th { font-weight: 700; }
  &.table-inverse {
    background-color: $gray-darker;
    color: $white;
    thead th, td, th { border-color: $border-light-color; }
  }
}

// Table head options
.thead-inverse th {
  background-color: $gray-darker;
  color: $white;
}
.thead-default th {
  background-color: $gray-lighter;
  color: $body-color;
}

// Table striped
.table-striped {
  tbody tr:nth-of-type(odd) { background-color: $gray-lighter; }
  &.table-inverse {
    tbody tr:nth-of-type(odd) { background-color: rgba($black, .1); }
  }
}

// Table hover
.table-hover {
  tbody tr:hover { background-color: $gray-lighter; }
  &.table-inverse {
    tbody tr:hover { background-color: rgba($black, .1); }
  }
}
