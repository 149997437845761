//
// Block Title
// --------------------------------------------------

.block-title {
  position: relative;
  padding-bottom: 13px;
  &::after { @extend %divider; }
  > small {
    padding: 8px 0 5px 0;
    font: {
      family: $font-family-base;
      size: $font-size-md;
      weight: normal;
    }
    letter-spacing: 0;
  }
  .block-title-icon {
    display: block;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
    background-color: rgba($primary, .1);
    color: $primary;
    font-size: 20px;
    line-height: 44px;
    text-align: center;
  }
  &.text-white::after { background: $white !important; }
}

// Alignment
.text-center {
  .block-title,
  .animated-digits,
  &.block-title,
  &.animated-digits {
    &::after {
      left: 50%;
      margin-left: -18px;
    }
    .block-title-icon {
      margin: {
        right: auto;
        left: auto;
      }
    }
  }
}
.text-right {
  .block-title,
  .animated-digits,
  &.block-title,
  &.animated-digits {
    &::after {
      right: 1px;
      left: auto;
    }
    .block-title-icon {
      margin: {
        right: 0;
        left: auto;
      }
    }
  }
}
@media (min-width: $screen-md) {
  .text-md-left {
    .block-title,
    .animated-digits,
    &.block-title,
    &.animated-digits {
      &::after {
        left: 1px;
        margin-left: 0;
      }
      .block-title-icon {
        margin: {
          right: 0;
          left: 0;
        }
      }
    }
  }
}
